import type { IconProps as Props } from './types';

export const IconCyberMonday = ({
  className,
  dataTestId = 'icon-cyber-monday',
  ...rest
}: Props) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      {...rest}
      viewBox="0 0 100 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15732 38.675V19.9529L81.0772 4.12891V22.8853L97.8726 19.2977V40.0603L2.12793 60.5119V39.7493L7.15732 38.675Z"
        fill="#0A0814"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23048 39.7179L7.24907 38.648V20.0019L81.0102 4.24208V22.9223L97.7695 19.3493V40.0276L2.23048 60.3962V39.7179ZM83.2407 20.1549L97.3067 17.156C97.9649 17.0157 98.6508 17.1815 99.1736 17.6072C99.6963 18.0329 100 18.673 100 19.3493V40.0276C100 41.0866 99.2629 42.0011 98.2323 42.2209L2.69327 62.5895C2.03514 62.7298 1.34916 62.564 0.826413 62.1383C0.303665 61.7126 0 61.0725 0 60.3962V39.7179C0 38.659 0.737117 37.7444 1.7677 37.5247L5.01859 36.8316V20.0019C5.01859 18.9433 5.7552 18.0289 6.78533 17.8088L80.5464 2.04901C81.2047 1.90838 81.8909 2.07398 82.4139 2.49967C82.9368 2.92537 83.2407 3.56565 83.2407 4.24208V20.1549Z"
        fill="white"
      />
      <path
        d="M7.14844 42.0093L11.4232 41.0919L15.0488 49.441L15.2165 49.405L18.8421 39.4995L23.1169 38.582V53.3242L19.7559 54.045V44.9901L19.6372 45.0154L16.2065 54.7137L14.0588 55.1745L10.6281 46.8987L10.5094 46.924V56.0295L7.14845 56.7509V42.0093L7.14844 42.0093Z"
        fill="white"
      />
      <path
        d="M38.7626 42.5943C38.7626 44.2164 38.4604 45.656 37.8565 46.9135C37.252 48.1708 36.4352 49.2029 35.4055 50.0092C34.3757 50.8156 33.2218 51.3561 31.9432 51.6305C30.6602 51.9054 29.5052 51.8587 28.4777 51.4886C27.4506 51.1191 26.636 50.4362 26.0337 49.4401C25.432 48.4441 25.1309 47.1373 25.1309 45.5202C25.1309 43.8981 25.432 42.4588 26.0337 41.2018C26.636 39.9448 27.4507 38.9135 28.4777 38.1074C29.5052 37.3016 30.6602 36.7608 31.9432 36.4853C33.2218 36.211 34.3757 36.256 35.4055 36.6206C36.4352 36.9852 37.252 37.6662 37.8565 38.6642C38.4604 39.6622 38.7626 40.9722 38.7626 42.5943ZM35.2345 43.3515C35.2345 42.3917 35.1032 41.609 34.8408 41.003C34.5777 40.3973 34.2015 39.9767 33.7108 39.7411C33.2207 39.5057 32.6315 39.4618 31.9432 39.6094C31.2598 39.7562 30.6717 40.0526 30.1794 40.4991C29.6866 40.9456 29.3087 41.5281 29.0462 42.2468C28.7837 42.9655 28.6519 43.8046 28.6519 44.7644C28.6519 45.7241 28.7837 46.5069 29.0462 47.1128C29.3086 47.7185 29.6866 48.1391 30.1794 48.3739C30.6717 48.609 31.2598 48.653 31.9432 48.5065C32.6315 48.3588 33.2207 48.0619 33.7108 47.616C34.2014 47.17 34.5777 46.5877 34.8408 45.869C35.1032 45.1504 35.2345 44.3113 35.2345 43.3515V43.3515Z"
        fill="white"
      />
      <path
        d="M52.9214 32.1836V46.9254L49.9928 47.5539L44.3099 40.2655L44.2189 40.285V48.7933L40.7676 49.534V34.7921L43.7381 34.1546L49.3579 41.4422L49.4766 41.4166V32.9229L52.9214 32.1836H52.9214Z"
        fill="white"
      />
      <path
        d="M60.3633 45.3322L55.085 46.4652V31.7234L60.3562 30.592C61.8113 30.2797 63.0654 30.3045 64.118 30.6663C65.1711 31.0281 65.9836 31.6984 66.5554 32.6769C67.1272 33.6556 67.4131 34.9104 67.4131 36.4412C67.4131 37.9767 67.1283 39.3577 66.5587 40.5834C65.9891 41.8096 65.1771 42.8296 64.1218 43.6439C63.0664 44.4582 61.8134 45.021 60.3633 45.3323L60.3633 45.3322ZM58.5363 42.6869L60.231 42.3231C61.0304 42.1516 61.7078 41.8659 62.2632 41.4659C62.8187 41.0659 63.2396 40.5174 63.5255 39.8201C63.8114 39.1229 63.9541 38.244 63.9541 37.1835C63.9541 36.1229 63.8103 35.308 63.5222 34.7388C63.2336 34.1697 62.8094 33.8049 62.2496 33.6443C61.6893 33.4838 60.9999 33.4915 60.182 33.6669L58.5363 34.0202V42.6868L58.5363 42.6869Z"
        fill="white"
      />
      <path
        d="M78.4726 41.4452L82.182 40.6492L77.3639 26.9414L72.7684 27.9276L67.9502 43.7037L71.6597 42.9075L72.5992 39.6106L77.5311 38.5521L78.4726 41.4452V41.4452ZM73.4207 36.7278L75.007 31.1613L75.1181 31.1374L76.7078 36.0223L73.4207 36.7278V36.7278Z"
        fill="white"
      />
      <path
        d="M79.9795 26.3804L83.8355 25.5529L86.8545 31.0738L86.9802 31.0468L89.9992 24.2299L93.8552 23.4023L88.6324 34.3416V39.2651L85.2018 40.0014V35.0779L79.9795 26.3804H79.9795Z"
        fill="white"
      />
      <path
        d="M23.8055 25.3059L20.3193 26.0543C20.2725 25.695 20.1772 25.3818 20.0334 25.1151C19.8891 24.8487 19.6985 24.6351 19.4616 24.4748C19.2242 24.3145 18.9443 24.2128 18.6214 24.1694C18.2979 24.1259 17.9412 24.146 17.5508 24.2299C16.8581 24.3786 16.2619 24.6818 15.7625 25.1393C15.2626 25.5968 14.8792 26.1854 14.6119 26.9049C14.3445 27.6247 14.2111 28.4547 14.2111 29.3953C14.2111 30.3743 14.3467 31.1645 14.6189 31.766C14.8907 32.3673 15.274 32.7819 15.7696 33.009C16.2646 33.2363 16.8511 33.277 17.5301 33.1313C17.9113 33.0497 18.2587 32.9246 18.5724 32.7566C18.886 32.5883 19.1627 32.3813 19.4023 32.1358C19.6419 31.89 19.839 31.6101 19.9948 31.2959C20.1505 30.9816 20.2589 30.6407 20.3193 30.2726L23.8055 29.5457C23.7451 30.2115 23.5627 30.8936 23.2582 31.5923C22.9538 32.2911 22.5367 32.9578 22.0069 33.5921C21.4765 34.2267 20.8317 34.779 20.0715 35.2491C19.3113 35.7193 18.4411 36.0599 17.4604 36.2702C16.1676 36.5476 15.0116 36.502 13.9911 36.133C12.9706 35.7643 12.1657 35.0795 11.5754 34.0785C10.9846 33.0774 10.6895 31.7682 10.6895 30.1511C10.6895 28.529 10.9895 27.09 11.5891 25.8333C12.1886 24.5771 13 23.5464 14.0227 22.7414C15.0453 21.9364 16.1911 21.3978 17.4604 21.1253C18.3252 20.9397 19.1246 20.8916 19.8592 20.9812C20.5932 21.0709 21.2396 21.2919 21.7972 21.6449C22.3554 21.9979 22.8085 22.4799 23.157 23.0914C23.506 23.7028 23.7222 24.441 23.8055 25.3059L23.8055 25.3059Z"
        fill="white"
      />
      <path
        d="M25.0625 19.6968L28.9185 18.8693L31.9375 24.3902L32.0633 24.3632L35.0822 17.5463L38.9388 16.7188L33.716 27.658V32.5815L30.2853 33.3178V28.3943L25.0625 19.6968H25.0625Z"
        fill="white"
      />
      <path
        d="M40.3955 31.1502V16.4084L46.3502 15.1302C47.4191 14.9009 48.3155 14.8643 49.0381 15.0213C49.7613 15.178 50.3047 15.4981 50.6701 15.9811C51.035 16.4642 51.2174 17.0754 51.2174 17.8144C51.2174 18.3758 51.1036 18.9006 50.8754 19.3884C50.6478 19.8764 50.3341 20.3074 49.9344 20.6811C49.5347 21.0547 49.0718 21.3555 48.5469 21.5834V21.7275C49.123 21.6326 49.6567 21.6788 50.1468 21.8663C50.6374 22.0538 51.0311 22.376 51.329 22.8327C51.6263 23.2896 51.775 23.873 51.775 24.5832C51.775 25.375 51.5801 26.1236 51.1896 26.8288C50.7992 27.5343 50.2339 28.1449 49.495 28.661C48.756 29.1773 47.8586 29.5485 46.8032 29.775L40.3955 31.1502L40.3955 31.1502ZM43.8469 21.7283L45.7577 21.3184C46.134 21.2375 46.4689 21.1009 46.7619 20.9084C47.0543 20.716 47.2835 20.4798 47.4485 20.1997C47.6135 19.9195 47.6958 19.6066 47.6958 19.2611C47.6958 18.767 47.5264 18.4145 47.1872 18.2042C46.8474 17.9939 46.3899 17.9507 45.8133 18.0745L43.8469 18.4965V21.7283ZM43.8469 27.5373L45.9804 27.0796C46.7292 26.9187 47.2814 26.654 47.6369 26.285C47.9925 25.9161 48.1701 25.4698 48.1701 24.9467C48.1701 24.5678 48.084 24.2588 47.9119 24.0196C47.7399 23.7807 47.4959 23.6193 47.1801 23.536C46.8637 23.4527 46.4852 23.4584 46.0436 23.5533L43.8469 24.0245V27.5373H43.8469Z"
        fill="white"
      />
      <path
        d="M53.4297 28.3557V13.6139L63.3868 11.4766V14.3705L56.8811 15.7666V18.79L62.8777 17.5028V20.4039L56.8811 21.6908V24.7214L63.3868 23.325V26.2187L53.4297 28.3557V28.3557Z"
        fill="white"
      />
      <path
        d="M73.3844 24.0661L77.1494 23.2581L74.1874 18.2817C74.9741 17.7412 75.5834 17.0713 76.0129 16.2712C76.4453 15.4659 76.6615 14.5521 76.6615 13.5299C76.6615 12.5222 76.4496 11.6955 76.0271 11.0497C75.6039 10.4039 75.0028 9.96647 74.2246 9.73772C73.4459 9.50898 72.5218 9.50926 71.4528 9.73882L65.5537 11.0049V25.7467L69.0051 25.0061V19.9601L70.773 19.5806L73.3844 24.0661H73.3844ZM69.0051 13.1362L70.7068 12.771C71.2225 12.6603 71.657 12.6424 72.0105 12.7177C72.3639 12.7933 72.6312 12.9613 72.8126 13.2225C72.9939 13.4834 73.0843 13.8419 73.0843 14.2977C73.0843 14.7536 72.9939 15.1462 72.8126 15.4753C72.6312 15.8046 72.365 16.0779 72.0137 16.2946C71.663 16.5115 71.2269 16.6759 70.7068 16.7875L69.0051 17.1528V13.1362V13.1362Z"
        fill="white"
      />
    </svg>
  );
};
