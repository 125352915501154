import type { IconProps as Props } from './types';

export const IconBlackFriday = ({ className, ...rest }: Props) => {
  return (
    <svg
      data-testid="desktop-banner"
      className={className}
      {...rest}
      viewBox="0 0 123 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_707_37974"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-0.0878906"
        width="123"
        height="79"
        fill="black"
      >
        <rect fill="white" y="-0.0878906" width="123" height="79" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6953 50.689L3.96665 52.548C3.46891 52.6541 3 52.2745 3 51.7656V25.01C3 24.6324 3.26403 24.3062 3.63335 24.2275L103.629 2.92996C104.127 2.82395 104.596 3.2035 104.596 3.71241V28.7305L119.444 25.5879C119.942 25.4826 120.41 25.862 120.41 26.3705V53.1266C120.41 53.5046 120.146 53.831 119.776 53.9092L13.661 76.3677C13.1635 76.473 12.6953 76.0936 12.6953 75.5851V50.689Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6953 50.689L3.96665 52.548C3.46891 52.6541 3 52.2745 3 51.7656V25.01C3 24.6324 3.26403 24.3062 3.63335 24.2275L103.629 2.92996C104.127 2.82395 104.596 3.2035 104.596 3.71241V28.7305L119.444 25.5879C119.942 25.4826 120.41 25.862 120.41 26.3705V53.1266C120.41 53.5046 120.146 53.831 119.776 53.9092L13.661 76.3677C13.1635 76.473 12.6953 76.0936 12.6953 75.5851V50.689Z"
        fill="black"
      />
      <path
        d="M12.6953 50.689H15.0953V47.724L12.1954 48.3416L12.6953 50.689ZM3.96665 52.548L3.4667 50.2007L3.46669 50.2007L3.96665 52.548ZM3.63335 24.2275L4.1333 26.5749L4.1333 26.5749L3.63335 24.2275ZM103.629 2.92996L104.129 5.27731V5.27731L103.629 2.92996ZM104.596 28.7305H102.196V31.6916L105.093 31.0785L104.596 28.7305ZM119.444 25.5879L118.948 23.2399L118.948 23.2399L119.444 25.5879ZM119.776 53.9092L120.273 56.2572L119.776 53.9092ZM13.661 76.3677L14.1579 78.7157H14.1579L13.661 76.3677ZM12.1954 48.3416L3.4667 50.2007L4.4666 54.8954L13.1953 53.0363L12.1954 48.3416ZM3.46669 50.2007C4.46218 49.9887 5.4 50.7478 5.4 51.7656H0.599998C0.599998 53.8012 2.47563 55.3195 4.46661 54.8954L3.46669 50.2007ZM5.4 51.7656V25.01H0.599998V51.7656H5.4ZM5.4 25.01C5.4 25.7652 4.87194 26.4176 4.1333 26.5749L3.1334 21.8802C1.65612 22.1948 0.599998 23.4996 0.599998 25.01H5.4ZM4.1333 26.5749L104.129 5.27731L103.129 0.58261L3.1334 21.8802L4.1333 26.5749ZM104.129 5.27731C103.134 5.48934 102.196 4.73024 102.196 3.71241H106.996C106.996 1.67677 105.12 0.158562 103.129 0.58261L104.129 5.27731ZM102.196 3.71241V28.7305H106.996V3.71241H102.196ZM105.093 31.0785L119.941 27.9358L118.948 23.2399L104.099 26.3825L105.093 31.0785ZM119.941 27.9358C118.946 28.1464 118.01 27.3875 118.01 26.3705H122.81C122.81 24.3366 120.937 22.8187 118.948 23.2399L119.941 27.9358ZM118.01 26.3705V53.1266H122.81V26.3705H118.01ZM118.01 53.1266C118.01 52.3706 118.539 51.7178 119.279 51.5612L120.273 56.2572C121.752 55.9442 122.81 54.6385 122.81 53.1266H118.01ZM119.279 51.5612L13.164 74.0197L14.1579 78.7157L120.273 56.2572L119.279 51.5612ZM13.164 74.0197C14.159 73.8092 15.0953 74.5681 15.0953 75.5851H10.2953C10.2953 77.619 12.168 79.1369 14.1579 78.7157L13.164 74.0197ZM15.0953 75.5851V50.689H10.2953V75.5851H15.0953Z"
        fill="white"
        mask="url(#path-1-outside-1_707_37974)"
      />
      <path
        d="M8.99219 47.7204V27.5712L17.1939 25.8242C18.6666 25.5108 19.9008 25.4612 20.8965 25.6754C21.8918 25.8896 22.6411 26.327 23.1437 26.9872C23.6466 27.6474 23.8978 28.4828 23.8978 29.4929C23.8978 30.2602 23.7407 30.9776 23.4272 31.6446C23.1133 32.3112 22.6812 32.9005 22.1307 33.4111C21.5798 33.9218 20.9426 34.3329 20.2192 34.6444V34.8413C21.0131 34.7117 21.7478 34.7748 22.4236 35.0311C23.099 35.2874 23.6417 35.7278 24.0516 36.352C24.4611 36.9765 24.6662 37.7739 24.6662 38.7445C24.6662 39.8268 24.397 40.85 23.8592 41.8139C23.3214 42.7781 22.5436 43.6127 21.5254 44.3181C20.5076 45.0238 19.2715 45.5311 17.8183 45.8407L8.99219 47.7204V47.7204ZM13.746 34.8425L16.3775 34.2822C16.8962 34.1717 17.3574 33.9849 17.7606 33.7219C18.1641 33.4588 18.4791 33.1361 18.7068 32.7531C18.934 32.3702 19.0477 31.9426 19.0477 31.4702C19.0477 30.795 18.8136 30.3132 18.3464 30.0257C17.8791 29.7383 17.2483 29.6793 16.4544 29.8484L13.746 30.4252V34.8425H13.746ZM13.746 42.7823L16.685 42.1566C17.716 41.9368 18.4761 41.5749 18.9659 41.0706C19.4557 40.5663 19.7006 39.9564 19.7006 39.2413C19.7006 38.7235 19.5821 38.3011 19.3454 37.9742C19.1084 37.6476 18.7724 37.4271 18.337 37.3132C17.9012 37.1993 17.3796 37.2072 16.7713 37.3369L13.746 37.981V42.7823H13.746Z"
        fill="white"
      />
      <path
        d="M26.9492 43.8963V23.7471L31.703 22.7344V38.9289L39.886 37.186V41.1411L26.9492 43.8963V43.8963Z"
        fill="white"
      />
      <path
        d="M56.2279 37.6601L61.3372 36.5718L54.7008 17.8359L48.3704 19.1838L41.7344 40.7467L46.8433 39.6585L48.1376 35.1523L54.931 33.7054L56.2279 37.6601V37.6601ZM49.2693 31.2121L51.4546 23.6037L51.6076 23.5711L53.7971 30.2477L49.2693 31.2121H49.2693Z"
        fill="white"
      />
      <path
        d="M79.8664 19.7755L75.0646 20.7983C75.0001 20.3068 74.8688 19.8792 74.6708 19.5147C74.4721 19.1502 74.2095 18.8586 73.8833 18.6395C73.5563 18.4204 73.1707 18.2814 72.726 18.222C72.2804 18.1626 71.7891 18.1901 71.2514 18.3047C70.2973 18.508 69.476 18.9225 68.7882 19.5477C68.0997 20.173 67.5717 20.9776 67.2034 21.961C66.8351 22.9448 66.6506 24.0793 66.6506 25.3648C66.6506 26.7029 66.8381 27.7829 67.2131 28.6047C67.5874 29.4269 68.1154 29.9932 68.7972 30.304C69.4798 30.6148 70.2876 30.6704 71.2229 30.4712C71.7471 30.3596 72.2264 30.1887 72.6584 29.9587C73.0905 29.7291 73.4715 29.4461 73.8015 29.1102C74.1308 28.7746 74.403 28.3921 74.6176 27.9625C74.8321 27.533 74.9806 27.0671 75.0646 26.5639L79.8664 25.5703C79.7831 26.4805 79.5318 27.4128 79.1126 28.3676C78.6933 29.3229 78.118 30.2341 77.3882 31.101C76.6584 31.9683 75.7704 32.7233 74.7233 33.3659C73.6762 34.0084 72.4777 34.474 71.1268 34.7615C69.3462 35.1407 67.7539 35.0783 66.3483 34.574C64.9427 34.0701 63.8334 33.134 63.0204 31.7654C62.2073 30.3976 61.8008 28.6081 61.8008 26.3978C61.8008 24.1807 62.2141 22.2139 63.0399 20.4962C63.8657 18.7793 64.9832 17.3705 66.3918 16.2702C67.8004 15.1699 69.3785 14.4338 71.1268 14.0614C72.3172 13.8077 73.4182 13.742 74.43 13.8645C75.4418 13.9866 76.3321 14.2891 77.1002 14.7716C77.869 15.2541 78.493 15.9128 78.9731 16.7486C79.4531 17.5843 79.7509 18.5933 79.8664 19.7755H79.8664Z"
        fill="white"
      />
      <path
        d="M82.5684 32.0501V11.9009L87.3221 10.8882V19.4086L87.5817 19.3533L94.0838 9.44821L99.6829 8.25586L93.2265 17.9579L99.8074 28.3784L94.122 29.5895L89.5696 22.1928L87.3221 25.5773V31.0378L82.5684 32.0501L82.5684 32.0501Z"
        fill="white"
      />
      <path d="M58.7948 42.1719V62.3218L54.041 63.3342V43.185L58.7948 42.1719Z" fill="white" />
      <path
        d="M19.248 70.7452V50.596L32.6746 47.7363V51.6918L24.0018 53.5383V57.6711L31.8195 56.0057V59.9709L24.0018 61.6356V69.7329L19.2481 70.7452H19.248Z"
        fill="white"
      />
      <path
        d="M46.3872 64.9648L51.573 63.86L47.4933 57.0584C48.5769 56.3199 49.4161 55.4044 50.0077 54.3108C50.6032 53.2097 50.901 51.9607 50.901 50.5635C50.901 49.1867 50.6092 48.0563 50.0272 47.174C49.4444 46.2909 48.6163 45.6934 47.5445 45.3808C46.472 45.0681 45.1991 45.0681 43.7268 45.3823L35.6016 47.1124V67.2616L40.3553 66.2492V59.3526L42.7904 58.834L46.3872 64.9648V64.9648ZM40.3553 50.0254L42.6992 49.5264C43.4095 49.3753 44.0081 49.3505 44.4948 49.4535C44.9816 49.5572 45.3499 49.7864 45.5997 50.1434C45.8494 50.5004 45.9739 50.9904 45.9739 51.6134C45.9739 52.2365 45.8494 52.7731 45.5997 53.2225C45.3499 53.6727 44.9831 54.0462 44.4993 54.3423C44.0163 54.6392 43.4155 54.8639 42.6992 55.0165L40.3553 55.5158V50.0254V50.0254Z"
        fill="white"
      />
      <path
        d="M69.5925 60.0237L62.3223 61.5719V41.4227L69.5827 39.8767C71.5868 39.4499 73.3142 39.4837 74.764 39.9782C76.2146 40.4727 77.3337 41.3889 78.1212 42.7266C78.9087 44.0637 79.3025 45.7787 79.3025 47.871C79.3025 49.9701 78.9102 51.8573 78.1257 53.5333C77.3412 55.2085 76.2228 56.6034 74.7692 57.7157C73.3157 58.8287 71.5898 59.5983 69.5924 60.0237H69.5925ZM67.076 56.408L69.4102 55.9104C70.5112 55.6759 71.4443 55.2859 72.2094 54.7388C72.9744 54.1924 73.5542 53.4423 73.9479 52.4894C74.3417 51.5364 74.539 50.3354 74.539 48.8856C74.539 47.4359 74.3402 46.3228 73.9434 45.5442C73.5459 44.7664 72.9616 44.2681 72.1906 44.0486C71.4188 43.8292 70.4692 43.8397 69.3427 44.0794L67.076 44.5619V56.408H67.076Z"
        fill="white"
      />
      <path
        d="M94.5341 54.7105L99.6434 53.6223L93.007 34.8867L86.6774 36.2343L80.041 57.7972L85.1503 56.7089L86.4444 52.2027L93.2373 50.7562L94.5341 54.7105L94.5341 54.7105ZM87.576 48.2623L89.7608 40.6542L89.9139 40.6218L92.1032 47.298L87.576 48.2623H87.576Z"
        fill="white"
      />
      <path
        d="M96.6113 34.1193L101.922 32.9882L106.081 40.5338L106.254 40.497L110.412 31.1799L115.723 30.0488L108.53 45.0003V51.7297L103.804 52.736V46.0066L96.6113 34.1193L96.6113 34.1193Z"
        fill="white"
      />
    </svg>
  );
};
